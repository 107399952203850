<template>
  <section id="reauthenticating">
    <auth-forms-wrapper width="392px" card-classes="px-0">
      <template #form>
        <template v-if="!hasError">
          <v-progress-linear
            rounded
            height="6"
            color="primary"
            :indeterminate="!hasError"
          />
          <div class="linear-progress-text">Authenticating</div>
        </template>
        <alert-message v-else :message="errorMessage" />
      </template>
    </auth-forms-wrapper>
  </section>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  AUTH_ERROR_CASE_MESSAGES,
  // MAROPOST_ADMIN_PANEL,
  // MAROPOST_ADMIN_PANEL_LOCAL,
  MAROPOST_ADMIN_PANEL_LOCAL,
  RESPONSE_CODES,
} from "@/constants/app";
import AlertMessage from "@/components/shared/AlertMessage.vue";

import AuthFormsWrapper from "@/components/shared/AuthFormsWrapper.vue";
import { getRefferer, getReffererDomain, inIframe } from "@/utils";

export default {
  name: "ReAuthentication",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    AuthFormsWrapper,
    AlertMessage,
  },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      errorMessage: "",
    };
  },
  /**
  |--------------------------------------------------
  | Computed
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      redirectToMarketing: "auth/redirectToMarketing",
      isLoggedIn: "auth/isLoggedIn",
      currentUser: "auth/currentUser",
      token: "auth/token",
      cookieToken: "auth/cookieToken",
    }),
    /**
     * hasError
     * @type {Boolean}
     */
    hasError() {
      return !!this.errorMessage;
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      verifyMaropostSession: "auth/verifyMaropostSession",
      fetchRealtimeToken: "auth/fetchRealtimeToken",
    }),
    /**
     * redirectTo
     * @description redirects user to specific view
     */
    redirectTo(params) {
      this.$router.push(params);
    },
    /**
     * verifyAndRefreshSession
     * @description Verifies and refreshes user session
     */
    async verifyAndRefreshSession() {
      try {
        if (this.redirectToMarketing) await this.verifyMaropostSession();
        else if (this.isLoggedIn) this.redirectTo({ name: "dashboard" });
        else this.redirectTo({ name: "login" });
      } catch (error) {
        this.errorMessage =
          AUTH_ERROR_CASE_MESSAGES[error?.code] ??
          error?.response?.data?.error ??
          error.message;
      }
    },
    /**
     * postMessageInParentWindow
     * @description Posts a message in parent window
     */
    postMessageInParentWindow(data, refferer) {
      window.parent.postMessage(data, refferer);
    },
    /**
     * iframePostTokenMessage
     * @description Posts message in Parent window
     */
    iframePostTokenMessage(data = { token: "" }, refferer) {
      this.postMessageInParentWindow(
        {
          ...data,
          code: RESPONSE_CODES.success,
          userDetails: this.currentUser,
        },
        refferer
      );
    },
    async identifyAndAuthenticateUser() {
      const refferer = getRefferer();
      // Handles iframe based reauthentication
      if (inIframe() && refferer) {
        if (!this.isLoggedIn) {
          const payload = [{ code: RESPONSE_CODES.unAuthorised }, refferer];
          this.postMessageInParentWindow(...payload);
          return;
        } else if (MAROPOST_ADMIN_PANEL_LOCAL.includes(getReffererDomain())) {
          await this.fetchRealtimeToken();
          await this.verifyMaropostSession();
          this.iframePostTokenMessage(
            { token: this.token, cookie: this.cookieToken },
            refferer
          );
        }
      }
      // handles cookie based appa authentication such as Marketing
      else {
        this.verifyAndRefreshSession();
      }
    },
  },
  /**
  |--------------------------------------------------
  | Mounted life cycle
  |--------------------------------------------------
  */
  async mounted() {
    this.identifyAndAuthenticateUser();
  },
};
</script>
